<template>
  <div>
    <h1>Forgot Password</h1>
    <p class="body-2 mt-3 grey--text">
      Enter Your Email to get the OTP
    </p>
    <v-form v-model="isValid" ref="form" @submit.prevent="forgotPassword">
      <InputGroup
        type="email"
        label="Email"
        :rules="[rules.required, rules.validEmail]"
        v-model="form.email"
      />

      <Button
        block
        large
        class="my-10"
        @click="forgotPassword"
        :loading="loading.user"
        :disabled="!isValid"
        type="submit"
        >Submit</Button
      >
    </v-form>
  </div>
</template>

<script>
import { InputGroup, Button } from "@/components";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");
import {
  validEmail,
  required,
  minPasswordLength
} from "@/helpers/form-validations";

export default {
  name: "forgot-password",
  components: { InputGroup, Button },
  data() {
    return {
      isValid: false,
      form: {
        email: "",
        role: "seller"
      }
    };
  },
  computed: {
    ...mapState(["loading"]),
    rules() {
      return {
        validEmail,
        required,
        minPasswordLength
      };
    }
  },
  methods: {
    ...mapActions(["FORGOT_PASSWORD"]),
    forgotPassword() {
      if (this.$refs.form.validate()) {
        this.FORGOT_PASSWORD(this.form);
      }
    }
  }
};
</script>

<style scoped></style>
